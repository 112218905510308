<template>
  <Common v-if="!isSelecting" :topStyle="topStyle" :mainStyle="mainStyle">
    <template v-slot:headers>
      <div @click="back" class="back-img" />
      <div class="title">任务</div>
      <div style="margin-right: 1.79rem; margin-left: auto" />
    </template>
    <template>
      <div v-if="summary.clean_is_sub == '1'" class="flex-w detail-box">
        <div>
          <span>父任务:</span>
          <span class="dad-title">{{ $store.state.kv['clean_dadTitle'] }}</span>
        </div>
      </div>
      <div class="detail-box detail-title">
        <div class="html-text" v-html="summary.title"></div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">当前状态</div>
        <div class="flex-w" style="height: 1.71rem">
          <div
            v-if="summary.clean_status >= 0"
            :style="{ fontSize: '1.19rem', color: xIco[summary.clean_status].color }"
            class="flex-w status-ico"
          >
            <img
              style="margin-right: 0.17rem; width: 1.71rem; height: 1.71rem"
              :src="xIco[summary.clean_status].ico"
              alt
            />
            <span>{{ xIco[summary.clean_status].str }}</span>
          </div>
          <i @click="handleNext('clean_status')" class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-h detail-box height-auto">
        <div class="flex-w full-w between" style="height: 4.27rem">
          <div class="item-title">开始时间</div>
          <div class="flex-w">
            <span v-if="summary.clean_stime">{{ summary.clean_stime }}</span>
            <span v-else>选择时间</span>
            <i @click="handleNext('clean_stime')" class="el-icon-arrow-right"></i>
          </div>
        </div>
        <div class="el-divider" style="margin: 0"></div>
        <div class="flex-w full-w between" style="height: 4.27rem">
          <div class="item-title">结束时间</div>
          <div class="flex-w">
            <span v-if="summary.clean_etime">{{ summary.clean_etime }}</span>
            <span v-else>选择时间</span>
            <i @click="handleNext('clean_etime')" class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">任务紧急度</div>
        <div>
          <template v-if="summary.clean_level != '' && summary.clean_level >= 0">
            <img style="width: 1.17rem; height: 1.17rem" :src="tasklevel[summary.clean_level].icon" />
            <span>{{ tasklevel[summary.clean_level].value }}</span>
          </template>
          <i @click="handleNext('clean_level')" class="el-icon-arrow-right"></i>
        </div>
      </div>

      <div class="flex-w detail-box content">
        <div class="item-title">描述</div>
        <div v-html="summary.clean_des"></div>
      </div>
      <div class="flex-w detail-box" v-if="summary.is_subtask != 1">
        <div class="item-title">子任务</div>
        <div>
          <span>{{ summary.sub_sum }}</span>
          <i @click="handleNext('sub_sum')" class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="flex-w detail-box">
        <div class="item-title">附件</div>
        <div>
          <span>{{ summary.UploadSum }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </template>
  </Common>
  <formContainer v-else :para="formPara" @back="isSelecting = false" @handleChange="handleChange"></formContainer>
</template>

<script>
import { postDailyBoxInfoLis, postDailyBoxInfoMod } from '@/service/help';
import { memberMixin, xIco, tasklevel } from '@/common/js';
import { dateFormat } from '@/utils/utils';
import formContainer from '@/components/mobile/formContainer/index.vue';
export default {
  props: ['para'],
  mixins: [memberMixin],
  data() {
    return {
      xIco,
      tasklevel,
      topStyle: 'detail-top',
      mainStyle: 'detail',
      summary: {},
      comment: [],
      isSelecting: false,
      formPara: null,
    };
  },
  mounted() {
    this.getData();
    // this.handleNext('SubSum');
  },
  computed: {
    taskId() {
      return this.para.id.toString();
      // return '347';
    },
  },
  methods: {
    handleChange({ data, type }) {
      console.log('拿到了', data, type);
      //抽公共map
      if (type == 'sub_sum') {
        let id = data.id;
        this.$emit('back', id);
        this.$emit('goTo', 'taskMain', { data: { id, type: 'daily' } });
        return;
      }

      let commomMap = {
        clean_status: {
          value: data.toString(),
          api: postDailyBoxInfoMod,
        },
        clean_stime: {
          value: dateFormat('YY-MM-DD hh:mm:ss', new Date(data.value)),
          api: postDailyBoxInfoMod,
          check: () => {
            //开始时间不能大于结束时间
            let check = true;
            if (!this.summary.clean_etime) {
              check = true;
              console.log(this.summary.clean_etime, !this.summary.clean_etime);
            } else if (new Date(this.summary.clean_etime).getTime() < new Date(data.value)) {
              this.$toast.fail('开始时间不能大于结束时间');
              check = false;
            }
            return check;
          },
        },
        clean_etime: {
          value: dateFormat('YY-MM-DD hh:mm:ss', new Date(data.value)),
          api: postDailyBoxInfoMod,
          check: () => {
            let check = true;
            if (!this.summary.clean_stime) {
              check = true;
            } else if (new Date(this.summary.clean_stime).getTime() > new Date(data.value)) {
              console.log('结束时间不能小于开始时间');
              this.$toast.fail('结束时间不能小于开始时间');
              check = false;
            }
            return check;
          },
        },
        clean_level: {
          value: data.toString(),
          api: postDailyBoxInfoMod,
        },
      };
      let handler = commomMap[type];
      let value = handler.value;
      let subData = {
        id: this.taskId,
        key: type,
        value,
      };
      //走接口前，做参数检查正确或者不需要检查
      let check = handler.check ? handler.check() : true;
      if (check) {
        handler.api(subData).then(res => {
          handler.callback ? handler.callback(res) : null;
          commonCallback();
        });
      } else {
        this.isSelecting = false;
        //检查失败直接关
      }
      let commonCallback = () => {
        this.$toast.success('修改成功');
        this.$set(this.summary, type, value);
        this.isSelecting = false;
      };
    },
    handleNext(type) {
      console.log(type);
      let formTarget = {
        clean_status: { type, title: '选择任务状态', id: 'statusLis', value: this.summary[type] },
        clean_stime: { type, title: '选择开始时间', id: 'datePick', value: this.summary[type] },
        clean_etime: { type, title: '选择结束始时间', id: 'datePick', value: this.summary[type] },
        clean_level: { type, title: '选择任务紧急度', id: 'levelLis', value: this.summary[type] },
        sub_sum: { type, title: '子任务', id: 'dailyLis', value: this.taskId, addtask: true },
      };
      let data = formTarget[type] || null;
      this.$set(this, 'formPara', data);
      this.isSelecting = true;
    },
    getData() {
      //获取总览详情数据
      this.getSummary();
    },
    getSummary() {
      postDailyBoxInfoLis({ id: this.taskId.toString() }).then(res => {
        let data = JSON.parse(JSON.stringify(res.data.data));
        this.$store.commit('set', { type: 'taskInfo', data });
        this.summary = data;
        this.summary.clean_status = parseInt(this.summary.clean_status);
        if (data.clean_is_sub === '0') {
          //需要保存父任务的标题
          this.$store.commit('setkv', { k: 'clean_dadTitle', v: data.title });
          this.$store.commit('setkv', { k: 'clean_dadId', v: data.id });
        }
      });
    },
    parseData(time) {
      return time.substr(5, 2) + '月' + time.substr(8, 2) + '日' + time.substr(11, 5);
    },
    back() {
      this.$emit('back');
      this.$emit('goTo', 'taskMain', {
        handler: that => {
          that.traId.pop();
          if (that.traId.length > 0) {
            that.componentId = 'taskMain';
            let id = that.traId.pop();
            that.$set(that, 'para', { id, type: 'daily' });
          }
        },
      });
    },
  },
  components: {
    formContainer,
  },
};
</script>

<style lang="less">
.detail {
  height: 100%;
  background-color: #f3f7f9;
  overflow-y: auto !important;
  .dad-title {
    font-size: 1.45rem;
    font-weight: 400;
    color: #13bfff;
  }

  .height-auto;
  .between {
    justify-content: space-between;
  }
  .height-auto {
    height: auto !important;
  }
  .detail-box {
    height: 4.69rem;
    background-color: #fff;
    border-radius: 0.85rem;
    padding: 0 1.37rem;
    margin-bottom: 0.68rem;
    .item-title {
      flex: 0 0 33%;
      overflow: hidden;
    }
    span {
      margin-left: 0.17rem;
      vertical-align: middle;
    }
    img {
      vertical-align: middle;
    }
    .main-font;
    .between;
    .main-font {
      font-size: 1.45rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #334681;
    }
    // .el-icon-arrow-right {
    //   margin-left: 1.45rem;
    //   color: #c2c7d9;
    //   font-size: 1.19rem;
    //   font-weight: 600;
    //   &::before {
    //     content: '' \e6e0'';
    //   }
    // }
    // background: #f3f7f9;
    //参与人
    .join-one-box {
      overflow-x: scroll;
      justify-content: flex-end !important;
      .join_head {
        width: 2.05rem;
        height: 2.05rem;
        line-height: 2.05rem;
        font-size: 0.68rem;
        margin-right: 0.85rem;
        flex: 0 0 auto;
      }
    }
  }
  .detail-title {
    font-size: 1.71rem;
    font-weight: 500;
    .content;
    .html-text {
      overflow: hidden;
    }
  }
  //描述
  .content {
    padding: 1.37rem;
    height: auto;
  }
  //评论
  .comment {
    padding: 1.37rem;
    height: auto;
    ul {
      margin-top: 0.85rem;
      li {
        justify-content: flex-start;
        align-items: flex-start;
        .head {
          flex-shrink: 0;
        }
        .span {
          margin-left: 0.68rem;
          .name {
            font-size: 1.19rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 1.71rem;
            color: #334681;
          }
          .ctime {
            font-size: 1.02rem;
            font-family: PingFang SC;
            font-weight: 400;
            line-height: 1.45rem;
            color: #8590b3;
            margin-top: 0.17rem;
            margin-bottom: 0.51rem;
          }
          .comment-content {
            // height: auto;
            font-size: 1.37rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #334681;
          }
        }
      }
    }
  }
}
.el-divider {
  margin: 0;
  height: 1px;
  width: 100%;
  background-color: #e4e9f2;
}
</style>
