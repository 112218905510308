<template>
  <div class="daily-mobile">
    <template>
      <Common @handleCommon="handleCommon" topStyle="daily-top daily-auto-height" :mainStyle="mainStyle">
        <nav :style="navStyle">
          <ul class="flex-w tasktype-ul">
            <li
              v-for="item in funList"
              :key="item.key"
              @click="handleNav(item)"
              :class="{ aliveNav: aliveKey === item.key }"
            >
              <div :class="['broder-line', item.cla]">{{ item.level }}</div>
            </li>
          </ul>
          <div class="flex-w screen-search">
            <input class="search" placeholder="搜索" v-model="keyword" />
          </div>
        </nav>
        <template v-slot:headers>
          <div style="margin-left: 4.1rem; margin-right: 0"></div>
          <div style="margin-left: 4.1rem; margin-right: auto"></div>
          <div class="title">日事清</div>
          <div class="img img-clock" />
          <div class="el-icon-circle-plus-outline img img-add" @click="handleAddTask"></div>
        </template>
      </Common>
      <div class="flex-w result">
        <ul class="task-ul">
          <li v-for="task in taskLis" :key="task.id" @click="displayDetail(task)">
            <input
              type="checkbox"
              name="task"
              autocomplate="off"
              v-model="task.is_deal"
              @click.stop="infile(task.id)"
            />
            <span>{{ task.title }}</span>
            <img v-if="task.task_id === '-1'" class="ico" src="~@/assets/image/icon-daily/icon_gr_16.png" />
            <img v-else class="ico" src="~@/assets/image/icon-daily/icon_sjx_16.png" />
            <div class="background-change" />
          </li>
        </ul>
      </div>
    </template>
    <transition name="my-sheet">
      <div class="cover sheet-cover" style="z-index: 10000" v-show="isSelecting" @click.self="isSelecting = false">
        <div class="main-frame">
          <div class="flex-w head-nav">
            <span class="nav" @click.stop="isSelecting = false">取消</span>
            <span class="title">新建任务</span>
            <span class="nav" @click="addDailyTask">完成</span>
          </div>
          <div class="task-form">
            <input type="text" placeholder="请输入任务标题" v-model="nd_title" />
            <div class="muit-tab">
              <div class="tab-title">选择重要性</div>
              <ul class="flex-w tab-ul">
                <li
                  v-for="(item, ind) in funList"
                  :key="item.key"
                  :class="[item.cla, 'tab', nd_level == ind + 1 ? 'active-tab' : '']"
                  @click="handleClickNav(ind)"
                >
                  {{ item.level }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { postDailyDeal, postDailyStatusLis, postDailyBoxNew } from '@/service/help';
import { memberMixin, xIco, tasklevel as level, dailyTaskType } from '@/common/js';
export default {
  mixins: [memberMixin],
  props: ['para'],
  data() {
    return {
      // taskType,
      xIco,
      aliveKey: 'important-and-urgent',
      mainStyle: 'daily-main',
      openScreen: false,
      tabData: [
        { title: '个人收纳箱', ico: 'box', tabs: ['进行中', '归档'], curr: [] },
        { title: '我参与的任务', ico: 'my', tabs: ['进行中', '归档'], curr: [] },
      ],
      keyword: '',
      allTask: [],
      isSelecting: false,
      nd_title: '',
      nd_level: 1,
      formPara: {},
    };
  },
  mounted() {
    console.log(this.traId, this.taskId);
    this.$nextTick(() => {
      if (!!this.taskId) {
        if (this.para.type == 'daily') {
          this.showDailyById(this.taskId);
        } else {
          this.showById(this.taskId);
        }
      }
    });
    this.getData();
  },
  methods: {
    handleClickNav(ind) {
      this.nd_level = ind + 1;
    },
    addDailyTask() {
      //走接口
      postDailyBoxNew({ status: this.nd_level.toString(), title: this.nd_title }).then(() => {
        this.$toast.success('新增成功');
        this.isSelecting = false;
        this.getData();
      });
    },
    handleAddTask() {
      //添加任务
      this.nd_title = '';
      this.nd_level = this.status;
      this.isSelecting = true;
    },
    displayDetail({ id, task_id, title }) {
      console.log(id, task_id, title);
      if (task_id === '-1') {
        this.showDailyById(id);
      } else {
        this.showById(task_id);
      }
    },
    infile(id) {
      let fd = new FormData();
      fd.append('clean_id', id);
      postDailyDeal(fd).then(() => {
        this.getData();
      });
    },
    showDailyById(id) {
      //打开日事清
      this.$emit('goTo', 'dailyDetail', {
        data: { id },
        handler: that => {
          that.traId.push(id);
        },
      });
    },
    showById(id) {
      this.$emit('goTo', 'taskDetail', {
        data: { id },
        handler: that => {
          that.traId.push(id);
        },
      });
    },
    handleCommon() {
      //点击到了屏罩，关闭弹窗
      this.openScreen = false;
      console.log('handleCommon');
    },
    getData() {
      //获取数据
      postDailyStatusLis({ status: this.status.toString() }).then(res => {
        let data = res.data.data.lis;
        data = data.map(item => {
          if (item.is_deal === '0') {
            item.is_deal = false;
          } else {
            item.is_deal = true;
          }
          return item;
        });
        this.allTask = data;
      });
    },
    handleNav(item) {
      //顶部nav
      let key = item.key;
      this.aliveKey = key;
      this.getData();
    },
    handleTabClick(index, tab) {
      //标签点击
      let targetArr = this.tabData[index].curr;
      if (targetArr.includes(tab)) {
        targetArr = targetArr.filter(v => v != tab);
      } else {
        targetArr.push(tab);
      }
      this.$set(this.tabData[index], 'curr', targetArr);
      this.getData();
    },
  },
  computed: {
    taskId() {
      return (this.para && this.para.id) || 0;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    funList() {
      return dailyTaskType;
    },
    navStyle() {
      return 'border-radius:1rem';
    },
    status() {
      let status = '1';
      let statusMap = {};
      dailyTaskType.forEach((v, i) => {
        statusMap[v.key] = i + 1;
      });
      status = statusMap[this.aliveKey].toString();
      return status;
    },
    level() {
      let levels = [];
      let levelMap = {};
      level.forEach((v, i) => {
        levelMap[v.value] = i;
      });
      this.tabData[1].curr.forEach(l => {
        levels.push(levelMap[l]);
      });
      return levels;
    },
    taskLis() {
      return this.allTask.filter(v => {
        return v.title.indexOf(this.keyword) >= 0;
      });
    },
  },
};
</script>
<style lang="less">
@import '~@/assets/style/mobile/daily.less';
</style>
